import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Langs, LangStoreService } from './services/lang-store.service';
import {register} from 'swiper/element/bundle';
import {Swiper} from 'swiper/types';
import { MediaQueryService } from './services/media-query.service';

declare var $: any;
declare var lax: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('video', { static: false })
  private video: ElementRef | undefined;
  year = new Date().getFullYear();
  lang = Langs.RU;
  langs = Langs;
  signLink = 'https://app.easy-teach.ru/';

  subscriptions = new Subscription();

  @ViewChild('swiperRef')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  application: string = 'assets/img/content/application.png';
  imageWhy:string = 'assets/img/content/why.jpg'

  constructor(private langStoreService: LangStoreService) {
    this.lang = this.langStoreService.getCurrentLang() ?? Langs.RU;
  }

  navigate() {
    const currLanguage = this.langStoreService.getCurrentLang();
    window.location.href = `${this.signLink}?ui_locales=${currLanguage}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngOnInit(): void {
    if(this.lang === Langs.EN){
      this.application = 'assets/img/content/application_eng.svg'
      this.imageWhy = 'assets/img/content/why_en.svg'
    } else if(this.lang === Langs.KK) {
      this.application = 'assets/img/content/application.png'
      this.imageWhy = 'assets/img/content/why.jpg'
    }

  }

  ngAfterViewInit(): void {
    register();
    this.swiper = this.swiperRef?.nativeElement.swiper;
    const native = this.video!.nativeElement;
    native.muted = true;
    native.play();

    this.animate();
    this.subscribeForm();
  }

  prevSlide(){
    this.swiper?.slidePrev();
  }

  nextSlide(){
    this.swiper?.slideNext();
  }

  subscribeForm() {
    document!.querySelector('.form_feedback')!.addEventListener('submit', function (event: any) {
      if (event) {
        event.preventDefault();
      }
      var formData = new FormData(event.target);
      var request = new XMLHttpRequest();
      request.open('POST', 'https://api.easy-teach.ru/api/v1/landing/feedback');
      request.send(formData);
  
      document!.querySelector('.feedback')!.classList.add('is-sended');
    }, false);
  
  }

  watch() {
    const native = this.video!.nativeElement;
    var popupWrapper = $('#popup__wrapper_video');
    var staticWrapper = $('.video__container');
    var videoWrapper = $('.the_pidor');

    $.magnificPopup.open({
      items: {
        src: popupWrapper,
      },
      type: 'inline',
      closeOnBgClick: true,
      modal: false,
      //mainClass: 'mfp-fade',
      removalDelay: 300,
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      callbacks: {
        open: () => {
          native.currentTime = 0;
          native.muted = false;
          native.loop = false;
          if (native.paused) {
            $('.btn-ctrl.btn-play-pause').removeClass('playing');
          } else {
            $('.btn-ctrl.btn-play-pause').addClass('playing');
          }
        },
        beforeClose: () => {
          native.muted = true;
          native.loop = true;
        },
        afterClose: () => {
          staticWrapper.append(videoWrapper);
          if (native.paused) {
            native.play();
          }
        },
      },
    });
  }

  animate() {
    lax.init();

    // Add a driver that we use to control our animations
    lax.addDriver('scrollY', function () {
      return window.scrollY;
    });

    lax.addElements('.hero__illustrate--1', {
      scrollY: {
        translateY: [
          ['elCenterY', 'elOutY'],
          [0, '-elHeight / 15'],
        ],
      },
    });

    lax.addElements('.hero__illustrate--2', {
      scrollY: {
        translateY: [
          ['elCenterY', 'elOutY'],
          [0, 'elHeight / 40'],
        ],
      },
    });

    lax.addElements('.hero__illustrate--3', {
      scrollY: {
        translateY: [
          ['elCenterY', 'elOutY'],
          [0, '-elHeight / 30'],
        ],
      },
    });

    lax.addElements(
      '.application__illustrate--image, .feedback__illustration--1',
      {
        scrollY: {
          translateY: [
            ['elInY', 'elOutY'],
            ['-elHeight / 10', 'elHeight / 10'],
          ],
        },
      }
    );

    lax.addElements(
      '.application__illustrate--image-2, .feedback__illustration--2',
      {
        scrollY: {
          translateX: [
            ['elInY', 'elOutY'],
            ['elHeight / 10', '-elHeight / 10'],
          ],
        },
      }
    );

    lax.addElements('.anytime__illustrate--1', {
      scrollY: {
        translateY: [
          ['elInY', 'elOutY'],
          ['elHeight / 15', '-elHeight / 15'],
        ],
      },
    });

    lax.addElements('.anytime__illustrate--2', {
      scrollY: {
        translateY: [
          ['elInY', 'elOutY'],
          ['elHeight / 10', '-elHeight / 10'],
        ],
      },
    });

    lax.addElements(
      '.teachers__dot--1, .teachers__dot--2, .teachers__dot--3, .why__ball--1, .why__ball--2, .why__ball--3, .why__ball--4, .why__ball--5, .why__ball--6',
      {
        scrollY: {
          translateY: [
            ['elInY', 'elOutY'],
            ['elHeight / 2', '-elHeight / 2'],
          ],
        },
      }
    );

    lax.addElements('.start__illustration--1', {
      scrollY: {
        translateY: [
          ['elInY', 'elOutY'],
          [0, '-elHeight / 10'],
        ],
      },
    });

    lax.addElements('.start__illustration--2', {
      scrollY: {
        translateY: [
          ['elInY', 'elOutY'],
          [0, '-elHeight / 10'],
        ],
      },
    });

    lax.addElements('.start__illustration--3', {
      scrollY: {
        translateY: [
          ['elInY', 'elOutY'],
          [0, 'elHeight / 5'],
        ],
      },
    });
  }
}
