<div class="page page--authpage">
  <div class="page__header">
      <header class="header">
          <div class="container">
              <div class="header__logotype">
                  <a class="logotype" href="/">
                      <img class="desktop" src="assets/img/content/logo-01.svg">
                      <img class="mobile" src="assets/img/content/logo-02.svg">
                  </a>
              </div>
              <div class="header__action">
                <app-select></app-select>
                <a (click)="navigate()">{{'Войти' | transloco}}</a>
            </div>
          </div>
      </header>
  </div>
  <main class="page__content">
      <section class="section section--hero">
          <div class="container">
              <div class="hero__container">
                  <div class="hero__buttons mobile">
                      <a class="btn" type=" " href="http://app.easy-teach.ru"><span class="btn__label">{{'Начать' | transloco}}</span>
      </a>
                  </div>
                  <div class="video__container">
                      <div class="the_pidor">
                          <div class="overlay"></div>
                          <div class="video__wrapper">
                              <video #video class="hero__video" autoplay="autoplay" muted="muted" loop="loop" playsinline="playsinline" id="hero__video">
                                  <source src="assets/img/content/banner.mp4" type="video/mp4">
                              </video>
                              <div class="video__controls_wrapper">
                                  <div class="btn-ctrl btn-play-pause playing"></div>
                                  <div class="btn-ctrl btn-close"></div>
                              </div>
                              <div class="btn-ctrl btn-play-pause mobile playing"></div>
                              <div class="btn-ctrl btn-fullscreen mobile"></div>
                              <div class="btn-ctrl btn-close mobile"></div>
                          </div>
                          <img (click)="watch()" class="watch-video mobile" src="assets/img/content/watch-video.svg">
                      </div>
                  </div>
                  <div class="info__container">
                      <div class="hero">
                          <div class="hero__title">{{'Легко Учиться' | transloco}} </div>
                          <div class="hero__text">
                              <p>{{'Онлайн-платформа для поиска репетитора' | transloco}} </p>
                          </div>
                          <div class="hero__buttons">
                              <a class="btn" type=" " href="http://app.easy-teach.ru"><span class="btn__label">{{'Начать' | transloco}} </span>
          </a>
                              <img *ngIf="lang === langs.RU" class="watch-video" src="assets/img/content/watch-video.svg">
                              <img *ngIf="lang === langs.KK" class="watch-video" src="assets/img/content/watch-video-kk.svg">
                              <img *ngIf="lang === langs.EN" class="watch-video" src="assets/img/content/watch-video-en.svg">
                          </div>
                      </div>
                  </div>
              </div>
              <div id="popup__wrapper_video"></div>
          </div>
      </section>
      <section class="section section--features">
          <div class="container">
              <div class="features">
                  <div class="features__title">{{'Моментальный доступ к лучшим преподавателям' | transloco}}</div>
                  <div class="features__items js_slider_features">
                      <div class="features__wrapper" style="">
                          <div class="features_item" style="">
                              <div class="features_item__image">
                                  <img src="assets/img/content/illustrate_features_1.svg">
                              </div>
                              <div class="features_item__title">{{'Занятие — прямо сейчас' | transloco}} </div>
                              <div class="features_item__text">{{'Мгновенный поиск преподавателя для занятий от 30 мин' | transloco}} </div>
                          </div>
                          <div class="features_item" style="">
                              <div class="features_item__image">
                                  <img src="assets/img/content/illustrate_features_2.svg">
                              </div>
                              <div class="features_item__title">{{'Занятия с репетитором' | transloco}}</div>
                              <div class="features_item__text">{{'Выбираете подходящего репетитора и подходящее время без стресса' | transloco}}</div>
                          </div>
                          <div class="features_item" style="">
                              <div class="features_item__image">
                                  <img src="assets/img/content/illustrate_features_3.svg">
                              </div>
                              <div class="features_item__title">{{'Подготовка к ЕГЭ' | transloco}}</div>
                              <div class="features_item__text">{{'Выбирайте репетитора и готовьтесь с ним к ЕГЭ, когда удобно' | transloco}} </div>
                          </div>
                      </div>
                      <div class="features__pagination"><span class="page"></span><span class="page"></span><span class="page"></span></div>
                  </div>
              </div>
          </div>
      </section>
      <section class="section section--application">
          <div class="container">
              <div class="application">
                  <div class="application__title">{{'Знания становятся ближе' | transloco}} 
                      <img src="assets/img/content/idea.svg">
                  </div>
                  <div class="application__text">
                      <p>{{'Установите наше приложение на планшет или мобильный телефон,чтобы всегда быть на связи с вашими репетиторами' | transloco}} </p>
                  </div>
                  <div class="application__illustrate">
                      <div class="application__illustrate--image" style="transform: translate3d(1e-05px, -57px, 1e-05px);">
                          <img src="{{application}}">
                      </div>
                      <div class="application__illustrate--image-1">
                          <img src="assets/img/content/application_1.svg">
                      </div>
                      <div class="application__illustrate--image-2" style="transform: translate3d(56px, 1e-05px, 1e-05px);">
                          <img src="assets/img/content/application_2.svg">
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="section section--anytime">
          <div class="container">
              <div class="anytime">
                  <div class="anytime__title">{{'Занимайтесь везде и в любое время' | transloco}}</div>
                  <div class="anytime__text">
                      <p>{{'Находите репетитора для занятий сейчас или планируйте регулярные занятия с преподавателем через удобный календарь' | transloco}} </p>
                  </div>
                  <div class="anytime__illustrate">
                      <img class="anytime__illustrate--1" src="assets/img/content/illustrate_anytime_1.jpg" style="transform: translate3d(1e-05px, 28px, 1e-05px);">
                      <img class="anytime__illustrate--2" src="assets/img/content/illustrate_anytime_2.jpg" style="transform: translate3d(1e-05px, 45px, 1e-05px);">
                  </div>
                  <div class="anytime__buttons">
                      <a class="btn" type=" " href="https://auth.easy-teach.ru/auth/register?returnUrl=http://app.easy-teach.ru?ui_locales={{lang}}"><span class="btn__label">{{'Попробовать' | transloco}}</span>
      </a>
                  </div>
              </div>
          </div>
      </section>
      <section class="section section--teachers">
          <div class="teachers__dot teachers__dot--1" style="transform: translate3d(1e-05px, 25px, 1e-05px);"></div>
          <div class="teachers__dot teachers__dot--2" style="transform: translate3d(1e-05px, 48px, 1e-05px);"></div>
          <div class="teachers__dot teachers__dot--3" style="transform: translate3d(1e-05px, 65px, 1e-05px);"></div>
          <div class="container">
              <div class="teachers">
                  <div class="teachers__title">{{'Выбирайте из проверенных репетиторов' | transloco}}</div>
                  <div class="teachers__text">{{'База данных из более чем 1000 репетиторов, проверенных и отобранных' | transloco}}.</div>
              </div>
          </div>
          <div class="teachers__container">
              <div class="teachers__items js_slider_teachers swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                  <swiper-container slides-per-view="auto" #swiperRef class="teachers__wrapper" style="transform: translate3d(0px, 0px, 0px);">
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-makarov.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Макаров Иван Андреевич' | transloco}}</div>
                          <div class="teachers_item__text">{{'Кандидат физ-мат наук. Тренер сборной г. Москвы по физике' | transloco}}.</div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-kolodeznykh.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Полина Сергеевна' | transloco}} </div>
                          <div class="teachers_item__text">{{'Репетитор' | transloco}}</div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-lukyanov.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Илья Владимирович' | transloco}} </div>
                          <div class="teachers_item__text">{{'Преподаватель физики' | transloco}} </div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-zimina.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Ирина Андреевна' | transloco}}</div>
                          <div class="teachers_item__text">{{'Учитель алгебры, геометрии и физики' | transloco}}</div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-paryev.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Артём Эдуардович' | transloco}} </div>
                          <div class="teachers_item__text">{{'Преподаватель физики, алгебры и физики' | transloco}}</div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-melnik.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Александр Дмитриевич' | transloco}}</div>
                          <div class="teachers_item__text">{{'Преподаватель физики' | transloco}}</div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-kolodeznykh.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Полина Сергеевна' | transloco}} </div>
                          <div class="teachers_item__text">{{'Репетитор' | transloco}}</div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-lukyanov.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Илья Владимирович' | transloco}} </div>
                          <div class="teachers_item__text">{{'Преподаватель физики' | transloco}} </div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-zimina.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Ирина Андреевна' | transloco}}</div>
                          <div class="teachers_item__text">{{'Учитель алгебры, геометрии и физики' | transloco}}</div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-paryev.jpg">
                          </div>
                          <div class="teachers_item__name">{{'Артём Эдуардович' | transloco}}</div>
                          <div class="teachers_item__text">{{'Преподаватель физики, алгебры и физики' | transloco}}</div>
                      </swiper-slide>
                      <swiper-slide class="teachers_item">
                          <div class="teachers_item__image">
                              <img src="assets/img/content/repetitor-more.jpg">
                          </div>
                          <div class="teachers_item__name">{{'1028 учителей' | transloco}}</div>
                          <div class="teachers_item__text">{{'И с каждым днем все больше' | transloco}}</div>
                      </swiper-slide>
                  </swiper-container>
              </div>
          </div>
          <div class="container teachers__action">
              <button class="slider__button slider__button--prev" (click)="prevSlide()">
                  <img src="assets/img/svg/icon-arr-prev.svg" />
              </button>
              <button class="slider__button slider__button--next" (click)="nextSlide()">
            
                  <img src="assets/img/svg/icon-arr-next.svg" />

              </button>
          </div>
      </section>
      <section class="section section--why">
          <div class="container">
              <div class="why">
                  <div class="why__ball why__ball--1" style="transform: translate3d(1e-05px, 30px, 1e-05px);">
                     
                      <img src="assets/img/svg/logotype-left.svg" />

                  </div>
                  <div class="why__ball why__ball--2" style="transform: translate3d(1e-05px, 50px, 1e-05px);">
                     
                      <img src="assets/img/svg/logotype-left.svg" />

                  </div>
                  <div class="why__ball why__ball--3" style="transform: translate3d(1e-05px, 50px, 1e-05px);">
                     
                      <img src="assets/img/svg/logotype-left.svg" />
                  </div>
                  <div class="why__ball why__ball--4" style="transform: translate3d(1e-05px, 75px, 1e-05px);">
             
                      <img src="assets/img/svg/logotype-left.svg" />
                  </div>
                  <div class="why__ball why__ball--5" style="transform: translate3d(1e-05px, 73px, 1e-05px);">
                     
                      <img style="width: 100%;" src="assets/img/svg/logotype-left.svg" />

                  </div>
                  <div class="why__ball why__ball--6" style="transform: translate3d(1e-05px, 22px, 1e-05px);">
               
                      <img src="assets/img/svg/logotype-left.svg" />
                  </div>
                  <div class="why__title">{{'Почему — Легко Учиться' | transloco}}?</div>
                  <div class="why__image">
                      <img src="{{imageWhy}}">
                  </div>
                  <div class="why__items">
                      <div class="why_item">
                          <div class="why_item__icon">
                             
                              <img  src="assets/img/svg/icon-why-time.svg" />
                          </div>
                          <div class="why_item__content">
                              <div class="why_item__title">{{'Занятия в удобное время' | transloco}}</div>
                              <div class="why_item__text">{{'Выбирайте удобное время для занятий, а платформа подскажет, кто из преподавателей доступен в это время' | transloco}}</div>
                          </div>
                      </div>
                      <div class="why_item">
                          <div class="why_item__icon">
                             
                              <img  src="assets/img/svg/icon-why-calendar.svg" />
                          </div>
                          <div class="why_item__content">
                              <div class="why_item__title">{{'Умный календарь' | transloco}}</div>
                              <div class="why_item__text">{{'Умный календарь напомнит о занятии и сам сообщит репетитору, если вы хотите изменить время занятия' | transloco}}</div>
                          </div>
                      </div>
                      <div class="why_item">
                          <div class="why_item__icon">
                             
                              <img  src="assets/img/svg/icon-why-video.svg" />
                          </div>
                          <div class="why_item__content">
                              <div class="why_item__title">{{'Сохраните запись' | transloco}}</div>
                              <div class="why_item__text">{{'Записи ваших уроков сохраняются на сервере, и вы можете посмотреть их или скачать' | transloco}}</div>
                          </div>
                      </div>
                  </div>
                  <div class="why__action">
                      <a class="btn" type=" " href="https://auth.easy-teach.ru/auth/register?returnUrl=http://app.easy-teach.ru?ui_locales={{lang}}"><span class="btn__label">{{'Попробовать' | transloco}}</span>
      </a>
                  </div>
              </div>
          </div>
      </section>
      <section class="section section--start">
          <div class="container">
              <div class="start">
                  <div class="start__title">{{'Преподавайте' | transloco}}
                      <br>{{'на Легко Учиться' | transloco}}</div>
                  <div class="start__illustration">
                      <img class="start__illustration--1" src="assets/img/content/illustrate_start_1.svg" style="transform: translate3d(1e-05px, 0px, 1e-05px);">
                      <img class="start__illustration--2" src="assets/img/content/illustrate_start_2.svg" style="transform: translate3d(1e-05px, 0px, 1e-05px);">
                      <img class="start__illustration--3" src="assets/img/content/illustrate_start_3.svg" style="transform: translate3d(1e-05px, 0px, 1e-05px);">
                  </div>
                  <div class="start__action">
                      <a class="btn" type=" " href="https://auth.easy-teach.ru/auth/register?returnUrl=http://app.easy-teach.ru?ui_locales={{lang}}"><span class="btn__label">{{'Зарегистрироваться' | transloco}}</span>
      </a>
                  </div>
              </div>
          </div>
      </section>
      <section class="section section--feedback">
          <div class="container">
              <div class="feedback__image">
                  <img src="assets/img/content/bg_feedback.jpg">
              </div>
              <div class="feedback__illustration">
                  <img class="feedback__illustration--1" src="assets/img/content/illustrate_feedback_1.svg" style="transform: translate3d(1e-05px, -14px, 1e-05px);">
                  <img class="feedback__illustration--2" src="assets/img/content/illustrate_feedback_2.svg" style="transform: translate3d(50px, 1e-05px, 1e-05px);">
              </div>
              <div class="feedback">
                  <div class="feedback__left">
                      <div class="feedback__title">{{'Остались вопросы?' | transloco}}</div>
                      <div class="feedback__text">
                          <p>{{'Пожалуйста, заполните форму, и мы обязательно свяжемся с вами' | transloco}}!</p>
                      </div>
                  </div>
                  <div class="feedback__right">
                      <form class="form_feedback" method="post" action="/">
                          <div class="form__field">
                              <div class="field">
                                  <div class="field__control">
                                      <div class="input_text">
                                          <input type="text" name="Name" id="form_feedback_name" [placeholder]="'Ваше имя' | transloco" required="required" autocomplete="off">
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="form__field">
                              <div class="field">
                                  <div class="field__control">
                                      <div class="input_text">
                                          <input type="text" name="Email" id="form_feedback_email" placeholder="E-mail" required="required" autocomplete="off">
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="form__field">
                              <div class="field">
                                  <div class="field__control">
                                      <div class="textarea">
                                          <textarea name="Message" [placeholder]="'Ваш вопрос' | transloco" id="form_feedback_text" required="required"></textarea>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="form__action">
                              <button class="btn btn--full" type="submit" ><span class="btn__label">{{'Отправить' | transloco}}</span>
                              </button>
                          </div>
                      </form>
                      <div class="form_sended">{{'Сообщение было успешно отправлено' | transloco}}.</div>
                  </div>
              </div>
          </div>
      </section>
  </main>
  <div class="page__footer">
      <footer class="footer">
          <div class="container">
              <div class="footer__logotype">
                  <svg class="icon__logotype-left" width="44px" height="78px">
                      <use xlink:href="#logotype-left"></use>
                  </svg>

                  <svg class="icon__logotype-right" width="84px" height="38px">
                      <use xlink:href="#logotype-right"></use>
                  </svg>

              </div>
              <div class="footer__menu"></div>
              <div class="footer__line"></div>
              <div class="footer__copyright">© {{'Легко учиться' | transloco}}, 2019-{{year}}</div>
              <div class="footer__nav"><a href="https://450203.selcdn.ru/documents/agreement.pdf" target="_blank">{{'Пользовательское соглашение' | transloco}}</a><a href="https://450203.selcdn.ru/documents/confidentiality.pdf" target="_blank">{{'Соглашение о конфиденциальности' | transloco}}</a><a href="https://450203.selcdn.ru/documents/tutor_info.pdf" target="_blank">{{'Информация для преподавателей' | transloco}}</a><a href="https://450203.selcdn.ru/documents/tutor_offer.pdf" target="_blank">{{'Оферта для преподавателей' | transloco}}</a>
              </div>
          </div>
      </footer>
  </div>
</div>
