import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
  export class LocalStorageService {
    localStorage: Storage;
  
    constructor() {
      this.localStorage = window.localStorage;
    }
  
    get(key: string): any {
      if (
        this.isLocalStorageSupported &&
        this.localStorage.getItem(key) &&
        this.localStorage.getItem(key) !== 'undefined'
      ) {
        return JSON.parse(this.localStorage.getItem(key) ?? '');
      }
  
      return null;
    }
  
    set(key: string, value: any): boolean {
      if (this.isLocalStorageSupported && key && value !== 'undefined') {
        this.localStorage.setItem(key, JSON.stringify(value));
  
        return true;
      }
  
      return false;
    }
  
    remove(key: string): boolean {
      if (this.isLocalStorageSupported) {
        this.localStorage.removeItem(key);
  
        return true;
      }
  
      return false;
    }
  
    clear() {
      this.localStorage.clear();
    }
  
    get isLocalStorageSupported(): boolean {
      return !!this.localStorage;
    }
  }
  