import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { LocalStorageService } from './local-storage.service';

export enum Langs {
  RU = 'ru',
  KK = 'kk',
  EN = 'en',
}

@Injectable({ providedIn: 'root' })
export class LangStoreService {
  private readonly currentLangKey = 'LuLnagCurrentLang';
  constructor(private localStorageService: LocalStorageService, @Inject(WINDOW) private windowRef: Window) {}

  setCurrentLang(lang: Langs) {
  
    this.localStorageService.set(this.currentLangKey, lang);
    this.windowRef.location.reload();
  }

  getCurrentLang(): Langs {
    return this.localStorageService.get(this.currentLangKey) ?? Langs.RU;
  }

}

